import React from 'react';
import Cookies from 'universal-cookie';

import NavHead from '../NavHead';
import LayoutContainer from '../EditorPanel/ManageSections/LayoutContainer';
import CookieWarning from '../CookieWarning';
import { formColor } from '../../helper';
import removeCookies from '../../helpers/removeCookies';
import { StoreProvider } from '../../helpers/Store';

import * as styles from './styles.module.css';
import BackToTopButton from './BackToTopButton';

const cookies = new Cookies();

class TabletView extends React.Component {
  constructor(props) {
    super(props);

    cookies.get('cookiesAccepted');

    this.state = {
      matches: null,
      isScrolling: false,
      mobile: false,
      cookieState: false,
      cookiePrev: false,
    };

    this.sentinel = React.createRef();
  }

  componentDidMount() {
    let mobile = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a,
        ) ||
        // eslint-disable-next-line no-useless-escape
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4),
        )
      )
        mobile = true;
    })(navigator.userAgent || navigator.vendor || window.opera);

    const breakpoint = '769px';
    this.mediaQueryList = window.matchMedia(`(min-width: ${breakpoint})`);
    this.mediaQueryList.addListener(this.updateMatches);

    this.setState({
      matches: window.matchMedia(`(min-width: ${breakpoint})`).matches,
      mobile,
    });

    if (this.props.pageContext.navigation.styles.fixed_top) {
      const elem = this.sentinel.current;
      if (elem) {
        const observer = new IntersectionObserver(this.handleScroll);
        observer.observe(elem);
      }
    }
  }

  componentWillUnmount() {
    if (this.mediaQueryList) {
      this.mediaQueryList.removeListener(this.updateMatches);
    }
  }

  handleScroll = (entries) => {
    if (this.props.pageContext.navigation.styles.fixed_top) {
      this.setState({
        isScrolling: !entries[entries.length - 1].isIntersecting,
      });
    }
  };

  updateMatches = () => {
    this.setState({
      matches: this.mediaQueryList.matches,
    });
  };

  documentReady = (callbackFunction) => {
    if (typeof document !== 'undefined') {
      if (document.readyState !== 'loading') {
        callbackFunction();
      } else {
        document.addEventListener('DOMContentLoaded', callbackFunction);
      }
    }
  };

  handleCookieClick = () => {
    if (typeof document !== 'undefined') {
      removeCookies();
      this.setState({ cookiePrev: this.state.cookieState, cookieState: !this.state.cookieState });
    }
  };

  render() {
    const { pageContext } = this.props;
    let ready = false;
    if (typeof document !== 'undefined') {
      this.documentReady(() => {
        ready = true;
      });
    }

    const render = [];
    let overlay;

    pageContext.page.sections.forEach((section, index) => {
      if (section.type === 'NAVIGATION') {
        ({ overlay } = section.styles);
      } else {
        render.push(
          <LayoutContainer
            pageContext={pageContext}
            key={`Layout_${pageContext.page.sections[index]._id}`}
            section={pageContext.page.sections[index]}
            overlay={
              ((index === 1 && !pageContext.category) ||
                (pageContext.category && pageContext.page.sections[index].type === 'ARTICLEMOTHER')) &&
              overlay === true
            }
            matches={this.state.matches}
            isScrolling={this.state.isScrolling}
            isFirst={index === 1}
            mobile={this.state.mobile}
            onCookieClick={this.handleCookieClick}
          />,
        );
      }
    });

    let consent;
    if (
      pageContext.cookie?.active &&
      pageContext.cookie?.consentPosition === 'bottomCenter' &&
      pageContext.cookie?.consentText
    ) {
      const recStyles = pageContext.page.sections.slice(-1)?.[0]?.styles;
      let bg;
      if (recStyles?.styles?.bg && recStyles.styles.bg.active) {
        let backgroundColor = recStyles.styles?.bg;
        if (!backgroundColor?.solid && !backgroundColor?.gradient?.from) backgroundColor = { solid: '#FFFFFF' };

        bg = formColor(
          backgroundColor,
          false,
          recStyles?.styles?.bg?.opacity ?? 1,
          undefined,
          pageContext.themeData?.colors,
        );
      }

      const consentLinkColor = pageContext.cookie.consentLinkColor
        ? formColor(
            {
              active: 'Solid',
              solid: pageContext.cookie.consentLinkColor,
            },
            undefined,
            pageContext.cookie.consentLinkOpacity,
            undefined,
            pageContext.themeData?.colors,
          )
        : null;

      consent = (
        <div className={`defaultTextLink ${styles.consent}`} style={bg}>
          <a
            href=""
            style={{
              color: consentLinkColor?.backgroundColor,
            }}
            onClick={(ev) => {
              ev.preventDefault();
              this.handleCookieClick();
            }}
          >
            {this.props.pageContext.cookie.consentText}
          </a>
        </div>
      );
    }

    return (
      <StoreProvider language={pageContext.metaLanguage}>
        <div className={styles.renderWrapper}>
          <div className={styles.renderContainer}>
            <div id="modal-root" />
            <div ref={this.sentinel} />
            <div id="tablet-container" className={styles.tabletContainer}>
              <NavHead
                isScrolling={this.state.isScrolling}
                pageContext={pageContext}
                metaDescription={pageContext?.category?.description}
                metaTitle={pageContext?.category?.name}
              />
              {pageContext.backToTopButton && pageContext.backToTopButton.active && (
                <BackToTopButton colour={pageContext.backToTopButton.colour} />
              )}
              {render}
              {consent}
            </div>
            {pageContext.cookie?.active === true &&
              (cookies?.cookies?.cookiesAccepted === undefined || this.state.cookieState !== this.state.cookiePrev) &&
              ready === true && (
                <CookieWarning
                  themeData={pageContext.themeData}
                  cookie={pageContext.cookie}
                  pagePathList={pageContext.pagePathList}
                  articlePathList={pageContext.articlePathList}
                  categoryPathList={pageContext.categoryPathList}
                  sectionSlugs={pageContext.sectionSlugs}
                  buttons={pageContext.buttons}
                  images={pageContext.images}
                  trackers={pageContext.seo.trackers}
                  id={pageContext.page._id}
                  onCookieClick={() => this.setState({ cookieState: false, cookiePrev: false })}
                  show={this.state.cookieState !== this.state.cookiePrev}
                />
              )}
          </div>
        </div>
      </StoreProvider>
    );
  }
}

export default TabletView;
